<template>
  <div class="study_del_bar flex-r-sa-c">
    <div class="left_btn flex-r-sa-c">
      <div class="left_btn_item flex-c-c-c" @click="openKeFu">
        <van-icon name="service-o" /><span>客服</span>
      </div>
      <div v-if="info.isCollect" class="left_btn_item flex-c-c-c" @click="doClickFavor(false)">
        <van-icon name="star" color="rgb(255, 181, 35)" /><span>取消收藏</span>
      </div>
      <div v-else class="left_btn_item flex-c-c-c" @click="doClickFavor(true)">
        <van-icon name="star-o" /><span>立即收藏</span>
      </div>
    </div>
    <div class="right_btn flex-r-s-s">
      <div v-if="!info.isBuy" class="right_btn_pay" @click="doClick">￥{{ info.salePrice }} 马上开课</div>
      <div v-if="info.isBuy && info.isLock === 0" class="right_btn_cart" @click="playVideo">已购买 开始学习</div>
      <div v-if="info.isLock === 1" class="right_btn_out">课程已被冻结</div>
    </div>
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />
  </div>
</template>

<script>
import { cloudroom_saveOrder, cloudroom_collect } from '@/api/study'
import kefuBtn from '@/components/kefu_btn'
export default {
  components: { kefuBtn },
  // eslint-disable-next-line vue/require-prop-types
  props: ['info'],
  data() {
    return {
    }
  },
  methods: {
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) },
    doClick() {
      const parms = { cloudRoomCourseId: this.info.cloudRoomCourseId, phone: this.info.mobile, expireType: this.info.exprieType }
      cloudroom_saveOrder(parms).then(res => {
        this.$router.push('/studyClass/studyOrder?id=' + res.data.orderId + '&type=1' + '&playNum=' + this.info.playNum)
      })
    },
    doClickFavor(status) {
      this.info.isCollect = status
      cloudroom_collect({cloudRoomCourseId: this.info.cloudRoomCourseId, isCollect: status}).then(res => {

      })
    },
    playVideo() { this.$emit('playvideo') }
  }
}
</script>

<style lang="scss">
.study_del_bar {
  position: fixed;
  width: 10rem;
  height: 1.71rem;
  background: #ffffff;
  opacity: 1;
  border-radius: 0rem;
  bottom: 0;
  .left_btn {
    width: 30%;
    .left_btn_item {
      font-size: 0.52rem;
      color: #686868;
    }
    span {
      font-size: 0.32rem;
      font-weight: 400;
      color: #686868;
    }
  }
  .right_btn {
    font-size: 0.37rem;
    line-height: 1.17rem;
    color: #ffffff;
    .right_btn_cart {
      width: 5.6rem;
      height: 1.17rem;
      background: #0767ab;
      border-radius: 0.59rem;
    }
    .right_btn_pay {
      width: 5.6rem;
      height: 1.17rem;
      background: #d1531c;
      border-radius: 0.59rem;
    }
    .right_btn_out {
      width: 5.6rem;
      height: 1.17rem;
      background: rgb(135, 153, 163);
      opacity: 1;
      border-radius: 0.59rem;
    }
  }
}
</style>
